//Production variable
const production = true

let localUrl = "http://localhost:4000";
let remoteURL = "https://backend.hoshii.co";
// let remoteURL = 'https://qa.backend.hoshii.co'

const ConfigServer = {
  urlServer: production ? remoteURL : localUrl,
};

export default ConfigServer;
