import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip, Button } from "@mui/material";
import { motion, useMotionValue } from "framer-motion";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

//social media icons
import facebook from "../../assets/socialMedia/facebook.png";
import instagram from "../../assets/socialMedia/instagram.png";
import snapchat from "../../assets/socialMedia/snapchat.png";
import linkedin from "../../assets/socialMedia/linkedin.png";
import tiktok from "../../assets/socialMedia/tiktok.png";
import twitter from "../../assets/socialMedia/twitter.png";
import onlyfans from "../../assets/socialMedia/onlyfans.png";

const socialMediaArrayImages = [
  {
    name: "facebook",
    image: facebook,
  },
  {
    name: "instagram",
    image: instagram,
  },
  {
    name: "twitter",
    image: twitter,
  },
  {
    name: "tiktok",
    image: tiktok,
  },
  {
    name: "linkedin",
    image: linkedin,
  },
  {
    name: "snapchat",
    image: snapchat,
  },
  {
    name: "onlyfans",
    image: onlyfans,
  },
];

const ChoiceSwipeProfile = ({
  data,
  handlerAccountLike,
  handlersAccountDislike,
  setChoiceDirection,
  cardRotation,
  setCardRotation,
  setOpen
}) => {
  const x = useMotionValue(0);

  const [mediaCount, setMediaCount] = useState(0);

  const [cardMediaList, setCardMediaList] = useState([]);

  const [cardInterface, setCardInterface] = useState(true);

  const [socialMediaArray, setSocialMediaArray] = useState([])

  // console.log(data)

  useEffect(() => {
    
    const availableSocialMedia = data.socialMedia.filter((item) => item.url !== "")

        const baseArray = [];

        availableSocialMedia.forEach(item => {

          socialMediaArrayImages.forEach(socialImage => {
            console.log(socialImage)
            if(socialImage.name === item.name){
              baseArray.push({
                name: item.name,
                url: item.url,
                image: socialImage.image
              })
            }
          })
        })

        // console.log(baseArray)

        setSocialMediaArray(baseArray);

  }, [data])
  const ChangeMedia = () => {
    if (cardInterface) {
      if (mediaCount < cardMediaList.length - 1) {
        setMediaCount(mediaCount + 1);
      } else if (mediaCount === cardMediaList.length - 1) {
        setMediaCount(0);
      }
    }
  };

  const LikeSwipe = () => {
    handlerAccountLike()
  };

  const DislikeSwipe = () => {
    handlersAccountDislike()
  };

  useEffect(() => {
    if (data) {
      const newList = [];

      newList.push(data.pic_profile);

      setCardMediaList(newList);
    }
  }, [data]);

  return (
    <motion.div
      drag="x"
      style={{ x }}
      dragSnapToOrigin
      whileDrag={{ rotate: cardRotation === "right" ? "10deg" : "-10deg" }}
      onDragEnd={(e) => {
        const direction =
          (e.target.getBoundingClientRect().left +
            e.target.getBoundingClientRect().right) /
          2;

        if (direction > window.innerWidth / 2 + window.innerWidth * 0.1) {
          LikeSwipe();
        }
        if (direction < window.innerWidth / 2 - window.innerWidth * 0.1) {
          DislikeSwipe();
        }

        setChoiceDirection("");
      }}
      onDrag={(e) => {
        const direction =
          (e.target.getBoundingClientRect().left +
            e.target.getBoundingClientRect().right) /
          2;

        if (direction > window.innerWidth / 2) {
          setCardRotation("right");
          setChoiceDirection("right");
        }
        if (direction < window.innerWidth / 2) {
          setCardRotation("left");
          setChoiceDirection("left");
        }
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: { xs: 0, md: 3 },
        }}
        key={data._id}
      >
        <Box
          component={"div"}
          sx={{
            width: { xs: "98vw", md: 400 },
            height: { xs: "80vh", md: 600 },
            borderRadius: { xs: 2, md: 5 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            boxShadow: "10px 10px 53px -14px rgba(0,0,0,0.75)",
            backgroundImage: `url(${
              cardMediaList.length > 0 && cardMediaList[mediaCount]
            })`,
            bgcolor: "white",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onClick={() => {
            ChangeMedia();
          }}
        />
        <Box
          sx={{
            width: { xs: "98vw", md: "100%" },
            borderRadius: { xs: "2vw 2vw 0 0", md: "1vw 1vw 0 0" },
            height: 65,
            position: "absolute",
            top: 0,
            background: "rgb(255,255,255)",
            background:
              "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6587885154061625) 29%, rgba(0,0,0,0.9052871148459384) 83%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            left: "50%",
            transform: "translateX(-50%)",
            opacity: cardInterface ? 1 : 0,
            transition: "0.3s ease",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { xs: "98vw", md: "100%" },
            }}
          >
            {/* <Box
              component={"div"}
              sx={{
                width: 45,
                height: 45,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${
                  data?.accountId
                    ? data.accountId.pic_profile
                    : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1681996179/chat-app-socket-test/vqueqktx6hcplgdmqcnz.png"
                })`,
                borderRadius: "50%",
                boxShadow: "0px 10px 50px 3px rgba(0,0,0,0.75)",
                ml: 1,
              }}
            /> */}
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "95%",
                  color: "white",
                }}
              >
                {data?.fullname}
              </Typography>
            </Box>
            {/* <Box  sx={{
          width: "0.1vw",
          height: "80%",
          bgcolor: "white"
        }}/> */}
            {/* <MoreHorizIcon
              sx={{ color: "white", ml: 2.5, cursor: "pointer" }}
            /> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "no-wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              mt: 1,
            }}
          >
            {cardMediaList.map((tag, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  height: 3,
                  bgcolor: index === mediaCount ? "#8B4DBA" : "white",
                  borderRadius: 10,
                  mx: 1,
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default ChoiceSwipeProfile;
