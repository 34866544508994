import React from "react";
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    MenuItem,
    Menu,
} from "@mui/material";
import Button from '@mui/material/Button';
import {
    isLastMessage,
    isSameSender,
    isSameSenderMargin,
    isSameUser,
} from "../../settings/getSender";

//icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

//
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";

import SendIcon from '@mui/icons-material/Send';

const ChatMessages = ({
    account,
    selectedChat,
    chats,
    load,
    messages,
    currentMessage,
    sendMesage,
    typingHandler,
    removeSelectedChat,
    isTyPing,
    unMatchHandler,
    token
}) => {
    const [chatAccountData, setChatAccountData] = React.useState(null);
    const messagesContainer = React.useRef(null);
    const [openConfirmModal, setOpenConfirmModal] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const unmatch = () => {
        handleClose()
        setOpenConfirmModal(true)
    }

    const navigate = useNavigate();

    // console.log(chatAccountData)

    // functions
    const extractInfoData = React.useCallback(() => {
        if (selectedChat) {
            let findSelectedChat = chats.find(
                (chat) => chat._id === selectedChat._id
            );
            // console.log(findSelectedChat)
            if (account && findSelectedChat) {
                let getAccountdata = findSelectedChat.accounts.filter(
                    (acc) => acc._id !== account._id
                );
                setChatAccountData(getAccountdata[0]);
            }
        }
    }, [account, chats, selectedChat]);

    React.useEffect(() => {
        extractInfoData();
    }, [extractInfoData]);

    React.useEffect(() => {
        if (messages.length > 0) {
            messagesContainer.current.scrollTop =
                messagesContainer.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Box
            display={{ base: selectedChat ? "flex" : "none", md: "block" }}
            sx={{
                width: { xs: "95%", md: "70%" },
                height: "85vh",
                m: 2,
                position: "relative",
                zIndex: 111,
            }}
        >
            {!selectedChat && (
                <>
                    <Box
                        display={{ xs: selectedChat ? "block" : "none", md: "block" }}
                        sx={{
                            height: "70vh",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{ fontSize: 22 }}>
                                Haz click en algun usuario para iniciar el chat
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            {load && selectedChat && (
                <>
                    <Box
                        sx={{
                            height: "70vh",
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{ fontSize: 22 }}>
                                Cargando mensajes...
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}

            {!load && selectedChat && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                pl: 2,
                                py: 1,
                            }}
                        >
                            <Box display={{ xs: "flex", md: "none" }}>
                                <IconButton
                                    sx={{ m: 2 }}
                                    aria-label="delete"
                                    onClick={() => removeSelectedChat()}
                                >
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{
                                    borderRadius: "50%",
                                    width: 55,
                                    height: 55,
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    transition: "ease-in-out 200ms",
                                    "&:hover": {
                                        backgroundColor: "#DEDEDE",
                                    },
                                }}
                                onClick={() => navigate("/public/" + chatAccountData._id)}
                            >
                                <Avatar
                                    alt={chatAccountData?.fullname}
                                    src={chatAccountData?.pic_profile}
                                    sx={{
                                        width: 45,
                                        height: 45,
                                    }}
                                />
                            </Box>
                            <Box
                                onClick={() => navigate("/public/" + chatAccountData._id)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginLeft: 2,
                                    cursor: "pointer",
                                }}
                            >
                                <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                                    {chatAccountData?.fullname}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <IconButton
                                sx={{ m: 2 }}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={unmatch}>Deshacer Match</MenuItem>
                                <MenuItem onClick={() => { navigate("/public/" + chatAccountData._id); handleClose() }}>Ir al perfil</MenuItem>
                                <MenuItem onClick={handleClose}>Reportar</MenuItem>
                            </Menu>
                        </Box>
                    </Box>

                    <Divider />

                    <Box
                        ref={messagesContainer}
                        sx={{
                            height: "60vh",
                            overflowY: "scroll",
                            "&::-webkit-scrollbar": { display: "none" }
                        }}
                    >
                        {messages &&
                            messages.map((m, i) => (
                                <Box style={{ display: "flex" }} key={m._id}>
                                    {(isSameSender(messages, m, i, account._id) ||
                                        isLastMessage(messages, i, account._id)) && (
                                            <Tooltip label={m.sender.fullname} placement="bottom-start">
                                                <Avatar
                                                    mt={"7px"}
                                                    mr={1}
                                                    size={"sm"}
                                                    name={m.sender.fullname}
                                                    src={m.sender.pic_profile}
                                                />
                                            </Tooltip>
                                        )}
                                    <span
                                        style={{
                                            backgroundColor: `${m.sender._id === account._id ? "#8B4DBA" : "#DEDEDE"
                                                }`,
                                            color: `${m.sender._id === account._id ? "white" : "black"
                                                }`,
                                            borderRadius: "20px",
                                            padding: "5px 15px",
                                            maxWidth: "75%",
                                            marginLeft: isSameSenderMargin(
                                                messages,
                                                m,
                                                i,
                                                account._id
                                            ),
                                            marginTop: isSameUser(messages, m, i, account._id)
                                                ? 3
                                                : 10,
                                        }}
                                    >
                                        {m.message}
                                    </span>
                                </Box>
                            ))}
                    </Box>
                    <Box>
                        {isTyPing ? (
                            <Typography component={"p"} sx={{ fontSize: 10, ml: 1, my: 2 }}>
                                Escribiendo...
                            </Typography>
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Box sx={{ height: "5vw", mt: 1 }}>
                        <Box component={"form"} onSubmit={sendMesage} sx={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                            <TextField
                                sx={{ width: "90%", bgcolor: "white", height: 50, borderRadius: 5, display: "flex", justifyContent: "center", paddingLeft: 2 }}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                placeholder={"Enter para enviar"}
                                value={currentMessage}
                                onChange={typingHandler}
                            />
                            <Button variant="contained" type="submit" color="primary"><SendIcon/></Button>
                        </Box>
                        {/* <FormControl sx={{width:'98%'}} onKeyDown={sendMesage}>
                                <OutlinedInput placeholder={'Enter para enviar'} value={currentMessage} onChange={typingHandler}/>
                            </FormControl> */}
                    </Box>

                    <ConfirmModal
                        openConfirmModal={openConfirmModal}
                        setOpenConfirmModal={setOpenConfirmModal}
                        unMatchHandler={unMatchHandler}
                        chatAccountData={chatAccountData}
                        token={token}
                        removeSelectedChat={removeSelectedChat}
                    />
                </>
            )}
        </Box>
    );
};

export default ChatMessages;
